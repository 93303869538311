import { useEffect, useState } from 'react';
import { ApiCall } from '../components/ApiCall';

const useEmailAlertsSettings = (alertID) => {
  const [emailSettings, setEmailSettings] = useState({ emailTo: [], emailCc: [] });

  useEffect(() => {
    const fetchData = async () => {
      const data = {
        'UpdateAlertID': 0,
        'AddUserID': 0,
        'DeleteAlertEmailID': 0
      };
      const response = await ApiCall('tq_AE_SettingsAlerts', data, 'tcpsql');
      if (response && response.data && response.data.Table2) {
        const emailToSettings = response.data.Table2.filter(item => item.AlertID === alertID && item.EmailTo);
        const emailCcSettings = response.data.Table2.filter(item => item.AlertID === alertID && item.EmailCC);
        setEmailSettings({
          emailTo: emailToSettings,
          emailCc: emailCcSettings,
        });
      }
    };

    fetchData();
  }, [alertID]);

  return emailSettings;
};

export default useEmailAlertsSettings;
