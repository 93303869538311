import axios from 'axios';
import { cryptAPIKey, encryptUsingAES256Base64 } from './Security/crypt';
import { postSendEmail } from '../config/apiconfig';

async function SendEmail(toAddress, ccAddress, subject, message, fromAddress) {
  let results, resultsMessage;

  try {
    var data = {
      'ToAddress': toAddress,
      'CcAddress': ccAddress,
      'Subject': subject,
      'Message': message,
      'Html': true,
      'FromAddress': fromAddress,
      'SendFromRemoteServer': false,
    };

    var requestHeaders = {
      'Content-Type': 'application/json; charset=utf-8',
      'X-ApiKey': cryptAPIKey(process.env.REACT_APP_SECRET_KEY)
    };

    var encryptedData = encryptUsingAES256Base64(JSON.stringify(data));

    const response = await axios.post(postSendEmail, JSON.stringify(encryptedData), { headers: requestHeaders, timeout: 40000 });

    if (response.status === 200) {
      results = 'Success';
      resultsMessage = 'API call was successful!';
    } else {
      results = 'Failed';
      resultsMessage = 'API call was not successful.';
    }
  } catch (error) {
    results = 'Failed';
    resultsMessage = error.message;
  } finally {
    return { results, resultsMessage };
  }
}

export default SendEmail;
